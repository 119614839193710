import { LatLng, icon } from 'leaflet';
import moment from 'moment';
import axios from 'axios';

import kapalOnline from '@/assets/images/map/online-ship.svg';
import kapalOffline from '@/assets/images/map/offline-ship.svg';

import AssetSvc from '@/services/AssetSvc';
import PulseSvc from '../services/PulseSvc';
import SummarySvc from '../services/SummarySvc';

const state = {
  assets: [],
};

const getLocation = async ({ lat, long }) => {
  try {
    const reverseGeoCodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyDowItyYfzjbq6S-7EouF2bj_bZDTGIbH8&language=id`;
    // eslint-disable-next-line no-await-in-loop
    const response = await axios.get(reverseGeoCodeUrl);
    const addressComponent = response.data.results[0] ? response.data.results[0].address_components : [];
    let formattedAddress = '-';

    if (addressComponent[1] && addressComponent[2]) {
      formattedAddress = `${addressComponent[1]?.short_name}, ${addressComponent[2]?.short_name}`;
    } else {
      // formattedAddress = addressComponent[0]?.short_name;
      // eslint-disable-next-line no-await-in-loop
      const fetchLocation = await fetch(`/geonames/findNearbyJSON?lat=${lat}&lng=${long}&username=rgibranz_dev`);
      // eslint-disable-next-line no-await-in-loop
      const location = await fetchLocation.json();
      formattedAddress = location?.geonames[0].toponymName ?? '-';
    }
    return formattedAddress;
  } catch (error) {
    console.error('error', error);
    return '-';
  }
};

const mutations = {
  UPDATE_ASSET_STATUS(state, { id, status }) {
    const asset = state.assets.find((asset) => asset.id === id);
    if (asset) {
      asset.status = status;
    }
  },
  UPDATE_ASSET_TRAIL(state, { massId, trail }) {
    const asset = state.assets.find((asset) => asset.massId === massId);
    if (asset) {
      asset.trail = trail;
    }
  },
  SET_ASSETS(state, assets) {
    state.assets = assets;
  },
};

const actions = {
  async getAssetsData({ commit }) {
    return AssetSvc.getAssets().then((res) => {
      commit('SET_ASSETS', res.data.data);
      return res.data.data;
    });
  },
  // eslint-disable-next-line no-unused-vars
  async getLastData({ commit }) {
    const currTime = Math.floor(Date.now() / 1000);
    const currAssets = Object.values(state.assets);
    const gpsDevices = [];

    currAssets.forEach((asset) => {
      const gpsDevice = asset.massDevice.find((v) => v.devcType === 'gps');
      if (gpsDevice) {
        gpsDevices.push(gpsDevice.devcUniqueId);
      }
    });

    const pulseDatas = await PulseSvc.getPulse({ params: { device: gpsDevices, type: 'gps' } });
    const gpsDatas = pulseDatas.data.data.series;

    const mapped = currAssets.map((asset) => {
      const gpsDevice = asset.massDevice.find((v) => v.devcType === 'gps');
      if (gpsDevice) {
        const gpsData = gpsDatas.find((d) => d.deviceId === gpsDevice.devcUniqueId);
        if (gpsData) {
          const diffTime = parseInt(currTime) - parseInt(gpsData.timestamp);

          asset.trail = false;
          asset.lastGpsData = gpsData;
          asset.lastLatLng = new LatLng(gpsData.latitude, gpsData.longitude);
          asset.dataStatus = diffTime < 600;
          asset.icon = icon({
            iconUrl: diffTime > 600 ? kapalOffline : kapalOnline,
            iconSize: [35, 61],
            iconAnchor: [12, 41],
            popupAnchor: [0, -41],
            tooltipAnchor: [-50, 30],
            className: 'ship',
          });
        }
      }
      return asset;
    });

    commit('SET_ASSETS', mapped);
    return mapped;
  },
  async fetchAllLastSummary({ commit }) {
    const assets = Object.values(state.assets);
    const mappedAssets = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const asset of assets) {
      const timestamp = asset.lastGpsData.timestamp;
      // eslint-disable-next-line no-await-in-loop
      const res = await SummarySvc.getSummary({
        start: moment(timestamp * 1000).subtract(1, 'day').unix(),
        end: moment(timestamp * 1000).unix(),
        aggregatedUnit: 'HOUR',
        devcType: ['gps', 'rpm', 'flowmeter', 'ae'],
        devcMassId: asset.massId,
        showDetails: true,
      });
      const summary = res.data.data;
      asset.summary = summary;
      asset.coordinates = Object.values(summary.gps.data).map((v) => [v.latitude, v.longitude]);

      mappedAssets.push(asset);
    }

    commit('SET_ASSETS', mappedAssets);
    return mappedAssets;
  },
  async getAllLocationData({ commit }) {
    try {
      const assets = Object.values(state.assets);
      const mappedAssets = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const asset of assets) {
        const lat = asset.lastGpsData.latitude;
        const long = asset.lastGpsData.longitude;
        const reverseGeoCodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyDowItyYfzjbq6S-7EouF2bj_bZDTGIbH8&language=id`;
        // eslint-disable-next-line no-await-in-loop
        const response = await axios.get(reverseGeoCodeUrl);
        const addressComponent = response.data.results[0] ? response.data.results[0].address_components : [];
        let formattedAddress = '-';

        if (addressComponent[1] && addressComponent[2]) {
          formattedAddress = `${addressComponent[1]?.short_name}, ${addressComponent[2]?.short_name}`;
        } else {
          // formattedAddress = addressComponent[0]?.short_name;
          // eslint-disable-next-line no-await-in-loop
          const fetchLocation = await fetch(`/geonames/findNearbyJSON?lat=${lat}&lng=${long}&username=rgibranz_dev`);
          // eslint-disable-next-line no-await-in-loop
          const location = await fetchLocation.json();
          formattedAddress = location?.geonames[0].toponymName;
        }

        asset.location = formattedAddress;
        mappedAssets.push(asset);
      }

      commit('SET_ASSETS', mappedAssets);
      return mappedAssets;
    } catch (error) {
      console.error('Error fetching reverse geocode:', error);
    }
  },
  updateAssetStatus({ commit }, payload) {
    commit('UPDATE_ASSET_STATUS', payload);
  },
  // eslint-disable-next-line no-unused-vars
  updateAssetTrail({ commit }, { massId, trail }) {
    const assets = Object.values(state.assets);

    assets.map((asset) => {
      if (asset.massId === massId) {
        asset.trail = trail;
      }
      return asset;
    });

    commit('SET_ASSETS', assets);
  },
  async fetchSummary({ commit }, {
    start,
    end,
    interval,
    massId,
  }) {
    const assets = Object.values(state.assets);
    const mappedAssets = [];

    const res = await SummarySvc.getSummary({
      start: moment(start).unix(),
      end: moment(end).unix(),
      aggregatedUnit: interval.toUpperCase(),
      devcType: ['gps', 'rpm', 'flowmeter', 'ae'],
      devcMassId: massId,
      showDetails: true,
    });

    // eslint-disable-next-line no-restricted-syntax
    for (const asset of assets) {
      if (asset.massId === massId) {
        const gpsDatas = Object.values(res.data.data.gps.data);
        const lastGpsData = gpsDatas[gpsDatas.length - 1];
        const coordinates = Object.values(res.data.data.gps.data).map((v) => [v.latitude, v.longitude]);
        const lastCoordinates = coordinates[coordinates.length - 1];

        const summary = res.data.data;
        asset.summary = summary;

        if (gpsDatas.length > 0) {
          asset.coordinates = Object.values(summary.gps.data).map((v) => [v.latitude, v.longitude]);
          asset.lastGpsData = lastGpsData;
          asset.lastLatLng = new LatLng(lastCoordinates[0], lastCoordinates[1]);
          // eslint-disable-next-line no-await-in-loop
          asset.location = await getLocation({ lat: lastCoordinates[0], long: lastCoordinates[1] });
        }
      }
      mappedAssets.push(asset);
    }

    commit('SET_ASSETS', mappedAssets);
    return mappedAssets;
  },
};

const getters = {
  assets: (state) => state.assets,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
