<template>
  <div style="max-width: 100vw !important; margin:0 auto !important;">
    <div class="d-flex align-items-center">
      <div class="ml-3">
        <span class="d-block text-muted small text-left font-weight-bold mb-2">Select Range</span>
        <v-date-picker v-model="dateRange" is-range mode="datetime" :masks="masks" is24hr>
          <template #default="{ inputValue, inputEvents, isDragging }">
            <div class="d-flex flex-column flex-sm-row justify-content-start align-items-center">
              <div class="position-relative flex-grow-1">
                <input class="form-control bg-light border rounded" style="width: 320px;"
                  :class="isDragging ? 'text-muted' : 'text-dark'" :value="inputValue.start + ' - ' + inputValue.end"
                  v-on="inputEvents.start" />
              </div>
            </div>
          </template>
        </v-date-picker>
      </div>

      <div class="ml-3">
        <span class="d-block text-muted small text-left font-weight-bold mb-2">Interval</span>
        <select name="" class="form-control" v-model="selectedInterval">
          <option value="minute">Minute</option>
          <option value="hour">Hour</option>
          <option value="day">Day</option>
          <option value="week">Week</option>
        </select>
      </div>

      <div class="ml-3">
        <span class="d-block text-muted small text-left font-weight-bold mb-2">Asset</span>
        <select name="" class="form-control" v-model="selectedAsset">
          <option v-for="asset in assets" :key="asset.massId" :value="asset.massId">{{ asset.massName }}</option>
        </select>
      </div>

      <div class="ml-3">
        <span class="d-block text-muted small text-left font-weight-bold mb-2">&nbsp;</span>
        <button class="btn btn-primary" @click="onApplyFilter">Apply</button>
      </div>
    </div>
    <div v-if="currAssetSummary" class="row w-100">
      <div class="col-md-12 mx-3 mt-3">
        <div class="iq-card m">
          <div class="iq-card-header pt-2"></div>
          <div class="iq-card-title">
            <h5 class="card-title ml-4">Bunker Summary</h5>
            <p class="ml-4">Last Update 15 Juli 2024, 23:00</p>
          </div>
          <div class="iq-card-body">
            <div class="chart-row">
              <!-- Total Flow Chart -->
              <div class="iq-card iq-card-block iq-card-stretch iq-card-height h-100 overflow-hidden">
                <div class="iq-card-body pb-0">
                  <!-- <div class="rounded-circle iq-card-icon iq-bg-primary"><i class="ri-exchange-dollar-fill"></i></div> -->
                  <span class="float-right chart-title">Total Flow</span>
                  <div class="clearfix"></div>
                  <div class="text-center">
                    <h2 class="mb-0">
                      <span class="counter">{{ currAssetSummary.TotalFlow }}</span>
                      <span class="counter-unit">liter</span>
                    </h2>
                    <!-- <p class="mb-0 text-secondary line-height">
                      <i class="ri-arrow-up-line text-success mr-1"></i>
                      <span class="text-success">10%</span> Increased
                    </p> -->
                  </div>
                </div>
                <ApexChart element="totalFlowChart" :chartOption="currAssetSummary.totalFlowChart"
                  :series="currAssetSummary.totalFlowChartSeries" />
              </div>
              <!-- ./ Total Flow Chart -->

              <!-- Mass Total Chart -->
              <div class="iq-card iq-card-block iq-card-stretch iq-card-height h-100 overflow-hidden">
                <div class="iq-card-body pb-0">
                  <span class="float-right chart-title">Mass Total</span>
                  <div class="clearfix"></div>
                  <div class="text-center">
                    <h2 class="mb-0">
                      <span class="counter">{{ currAssetSummary.MassTotal }}</span>
                      <span class="counter-unit">MT</span>
                    </h2>
                    <!-- <p class="mb-0 text-secondary line-height">
                      <i class="ri-arrow-up-line text-success mr-1"></i>
                      <span class="text-success">10%</span> Increased
                    </p> -->
                  </div>
                </div>
                <ApexChart element="massTotalChart" :chartOption="currAssetSummary.massTotalChart"
                  :series="currAssetSummary.massTotalChartSeries" />
              </div>
              <!-- ./ Mass Total Chart -->

              <!-- Mass Flowrate Chart -->
              <div class="iq-card iq-card-block iq-card-stretch iq-card-height h-100 overflow-hidden">
                <div class="iq-card-body pb-0">
                  <span class="float-right chart-title">Mass Flowrate</span>
                  <div class="clearfix"></div>
                  <div class="text-center">
                    <h2 class="mb-0">
                      <span class="counter">{{ currAssetSummary.MassFlowrate }}</span>
                      <span class="counter-unit">MT/Hour</span>
                    </h2>
                    <!-- <p class="mb-0 text-secondary line-height">
                      <span class="text-success">12</span> current flow
                    </p> -->
                  </div>
                </div>
                <ApexChart element="massFlowrateChart" :chartOption="currAssetSummary.massFlowrateChart"
                  :series="currAssetSummary.massFlowrateChartSeries" />
              </div>
              <!-- ./ Mass Flowrate Chart -->

              <!-- Density Chart -->
              <div class="iq-card iq-card-block iq-card-stretch iq-card-height h-100 overflow-hidden">
                <div class="iq-card-body pb-0">
                  <span class="float-right chart-title">Density</span>
                  <div class="clearfix"></div>
                  <div class="text-center">
                    <h2 class="mb-0">
                      <span class="counter">{{ currAssetSummary.Density }}</span>
                      <span class="counter-unit">g/cm<sup>3</sup></span>
                    </h2>
                    <!-- <p class="mb-0 text-secondary line-height">
                      <span class="text-success">0.87</span> Current Dens
                    </p> -->
                  </div>
                </div>
                <ApexChart element="densityChart" :chartOption="currAssetSummary.densityChart"
                  :series="currAssetSummary.densityChartSeries" />
              </div>
              <!-- ./ Density Chart -->

              <!-- Temprature Chart -->
              <div class="iq-card iq-card-block iq-card-stretch iq-card-height h-100 overflow-hidden">
                <div class="iq-card-body pb-0">
                  <span class="float-right chart-title">Temprature</span>
                  <div class="clearfix"></div>
                  <div class="text-center">
                    <h2 class="mb-0">
                      <span class="counter">{{ currAssetSummary.Temperature }}</span>
                      <span class="counter-unit">°C</span>
                    </h2>
                    <!-- <p class="mb-0 text-secondary line-height">
                      <span class="text-success">75</span> Current Temp
                    </p> -->
                  </div>
                </div>
                <ApexChart element="tempratureChart" :chartOption="currAssetSummary.tempratureChart"
                  :series="currAssetSummary.tempratureChartSeries" />
              </div>
              <!-- ./ Temprature Chart -->
            </div>
            <ApexChart element="chart" :chartOption="currAssetSummary.chartOption"
              :series="currAssetSummary.chartSeries" class="mt-5" />
          </div>
        </div>
      </div>

      <div class="col-md-12 mx-3 mt-3">
        <div class="iq-card">
          <div class="iq-card-header pt-2">
            <div class="iq-card-title">
              <h5 class="card-title ml-4">Datalog</h5>
              <p class="ml-4">Last Update 15 Juli 2024, 23:00</p>
            </div>
          </div>

          <div class="iq-card-body pt-2 pb-5 mb-5">
            <!-- <div class="map-section mb-3" style="min-height: 350px; width: 100%;">
              <Map :ships="ships" :center="center" :zoom="zoom"
                style="height: 100%;" />
            </div> -->
            <div class="table-responsive">
              <div style="height: 65vh; overflow-y: auto;">
                <table class="table">
                  <thead class="bg-dark text-white" style="position: sticky; top: 0;">
                    <tr>
                      <th scope="col">Date Time</th>
                      <th scope="col">Mass Total</th>
                      <th scope="col">Mass Flowrate</th>
                      <th scope="col">Total Flow</th>
                      <th scope="col">Density</th>
                      <th scope="col">Temperature</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="datalog in paginatedData" :key="datalog.id">
                      <td>{{ datalog.datetime }}</td>
                      <td>{{ datalog.mass_total }}</td>
                      <td>{{ datalog.mass_flowrate }}</td>
                      <td>{{ datalog.total_flow }}</td>
                      <td>{{ datalog.density }}</td>
                      <td>{{ datalog.temperature }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <hr />
            <nav>
              <ul class="pagination">
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                  <a class="page-link" href="#" @click.prevent="changePage(currentPage - 1)">&laquo;</a>
                </li>
                <li class="page-item" :class="{ active: n === currentPage }" v-for="n in totalPages" :key="n">
                  <a class="page-link" href="#" @click.prevent="changePage(n)">{{ n }}</a>
                </li>
                <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                  <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)">&raquo;</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
// import Map from '@/views/home/components/Map';
import { LatLng } from 'leaflet';
import moment from 'moment';
import { generateTimestampsInSeconds } from '@/util/date';

export default {
  name: 'BunkerDashboard',
  components: {
    // Map,
  },
  data() {
    return {
      center: new LatLng(0, 0),
      zoom: 10,
      currentPage: 1,
      perPage: 500,
      datarange: {
        start: new Date(2020, 0, 6),
        end: new Date(2020, 0, 23),
      },
      masks: {
        input: 'YYYY-MM-DD HH:mm ',
      },
      isLoading: true,
    };
  },
  async mounted() {
    console.time('dashboard mounted');
    const interval = this.$store.getters['filter/interval'];
    if (interval === 'minute') {
      this.$store.commit('filter/SET_INTERVAL', 'hour');
    }
    const date = this.$store.getters['filter/date'];

    let currentMassId = this.$store.getters['filter/currentMassId'];
    let assets = this.$store.getters['asset/assets'];

    if (assets.length === 0) {
      await this.$store.dispatch('asset/getAssetsData');
      await this.$store.dispatch('asset/getLastData');
      assets = this.$store.getters['asset/assets'];
    }

    if (currentMassId === null) {
      await this.$store.dispatch('filter/setCurrentMassId', assets[0].massId);
      currentMassId = this.$store.getters['filter/currentMassId'];
    }

    await this.$store.dispatch('asset/fetchSummary', {
      start: date.start,
      end: date.end,
      interval,
      massId: currentMassId,
    });

    this.isLoading = false;

    console.timeEnd('dashboard mounted');
  },
  computed: {
    ...mapState('asset', ['assets']),
    ...mapState('filter', {
      currentMassId: (state) => state.currentMassId,
      interval: (state) => state.interval,
    }),
    totalPages() {
      return Math.ceil(this.currAssetSummary.datalogs.length / this.perPage);
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.currAssetSummary.datalogs.slice(start, end);
    },
    currAsset() {
      const currMassId = this.$store.getters['filter/currentMassId'];
      const assets = this.$store.getters['asset/assets'];
      if (!assets) return null;

      return assets.find((asset) => asset.massId === currMassId);
    },
    currAssetSummary() {
      const summary = this.currAsset?.summary || null;
      const mappedSummary = {};
      console.log('Summary : ', summary);

      if (!summary) return null;

      mappedSummary.Temperature = summary?.flowmeter?.summary?.BUNKER_IN?._averageTemp.toFixed(2);
      mappedSummary.Density = summary?.flowmeter?.summary?.BUNKER_IN?._averageDens.toFixed(2);
      mappedSummary.MassFlowrate = summary?.flowmeter?.summary?.BUNKER_IN?._averageMassFlowrate.toFixed(2);
      mappedSummary.MassTotal = summary?.flowmeter?.summary?.BUNKER_IN?._totalMassFlow.toFixed(2);
      mappedSummary.TotalFlow = summary?.flowmeter?.summary?.BUNKER_IN?._totalVolumeFlow.toFixed(2);

      const timestamps = generateTimestampsInSeconds(moment(this.dateRange.start).unix(), moment(this.dateRange.end).unix(), this.interval === 'minute' ? 60 : 3600);

      const roundedFm = {};
      Object.keys(summary.flowmeter?.data || {}).forEach((key) => {
        const roundedKey = Math.round(key / 60) * 60;
        roundedFm[roundedKey] = summary.flowmeter?.data[key];
      });

      const mappedSeries = timestamps.map((timestamp) => {
        const fmData = roundedFm[timestamp] || {};
        return {
          timestamp,
          fmData,
        };
      });

      mappedSummary.totalFlowChartSeries = [{
        name: 'Total Flow',
        data: mappedSeries.length > 60
          ? mappedSeries.slice(-60).map((data) => (data.fmData.BUNKER_InFlow || 0).toFixed(2))
          : mappedSeries.map((data) => (data.fmData.BUNKER_InFlow || 0).toFixed(2)),
      }];
      mappedSummary.totalFlowChart = {
        chart: {
          height: 80,
          type: 'area',
          sparkline: {
            enabled: true,
          },
          group: 'sparklines',

        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 3,
          curve: 'smooth',
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.5,
            opacityTo: 0,
          },
        },
        series: mappedSummary.totalFlowChartSeries,
        colors: ['#00CA00'],
        xaxis: {
          type: 'datetime',
          categories: mappedSeries.length > 60
            ? mappedSeries.slice(mappedSeries.length - 60).map((data) => moment(data.timestamp * 1000).format('DD MMMM YYYY HH:mm'))
            : mappedSeries.map((data) => moment(data.timestamp * 1000).format('DD MMMM YYYY HH:mm')),
        },
        tooltip: {
          x: {
            enabled: false,
            format: 'dd/MM/yy HH:mm',
          },
        },
      };

      mappedSummary.massTotalChartSeries = [{
        name: 'Mass Total',
        data: mappedSeries.length > 60
          ? mappedSeries.slice(-60).map((data) => (data?.fmData?.BUNKER_IN?.massTotal || 0).toFixed(2))
          : mappedSeries.map((data) => (data?.fmData?.BUNKER_IN?.massTotal || 0).toFixed(2)),
      }];
      mappedSummary.massTotalChart = {
        chart: {
          height: 80,
          type: 'area',
          sparkline: {
            enabled: true,
          },
          group: 'sparklines',

        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 3,
          curve: 'smooth',
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.5,
            opacityTo: 0,
          },
        },
        series: mappedSummary.massTotalChartSeries,
        colors: ['#0084ff'],
        xaxis: {
          type: 'datetime',
          categories: mappedSeries.length > 60
            ? mappedSeries.slice(mappedSeries.length - 60).map((data) => moment(data.timestamp * 1000).format('DD MMMM YYYY HH:mm'))
            : mappedSeries.map((data) => moment(data.timestamp * 1000).format('DD MMMM YYYY HH:mm')),
        },
        tooltip: {
          x: {
            enabled: false,
            format: 'dd/MM/yy HH:mm',
          },
        },
      };

      mappedSummary.massFlowrateChartSeries = [{
        name: 'Mass Flowrate',
        data: mappedSeries.length > 60
          ? mappedSeries.slice(-60).map((data) => (data?.fmData?.BUNKER_IN?.massFlowrate || 0).toFixed(2))
          : mappedSeries.map((data) => (data?.fmData?.BUNKER_IN?.massFlowrate || 0).toFixed(2)),
      }];
      mappedSummary.massFlowrateChart = {
        chart: {
          height: 80,
          type: 'area',
          sparkline: {
            enabled: true,
          },
          group: 'sparklines',

        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 3,
          curve: 'smooth',
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.5,
            opacityTo: 0,
          },
        },
        series: mappedSummary.massFlowrateChartSeries,
        colors: ['#E64141'],
        xaxis: {
          type: 'datetime',
          categories: mappedSeries.length > 60
            ? mappedSeries.slice(mappedSeries.length - 60).map((data) => moment(data.timestamp * 1000).format('DD MMMM YYYY HH:mm'))
            : mappedSeries.map((data) => moment(data.timestamp * 1000).format('DD MMMM YYYY HH:mm')),
        },
        tooltip: {
          x: {
            enabled: false,
            format: 'dd/MM/yy HH:mm',
          },
        },
      };

      mappedSummary.densityChartSeries = [{
        name: 'Density',
        data: mappedSeries.length > 60
          ? mappedSeries.slice(-60).map((data) => (data?.fmData?.BUNKER_IN?.density || 0).toFixed(2))
          : mappedSeries.map((data) => (data?.fmData?.BUNKER_IN?.density || 0).toFixed(2)),
      }];
      mappedSummary.densityChart = {
        chart: {
          height: 80,
          type: 'area',
          sparkline: {
            enabled: true,
          },
          group: 'sparklines',

        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 3,
          curve: 'smooth',
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.5,
            opacityTo: 0,
          },
        },
        series: mappedSummary.densityChartSeries,
        colors: ['#FFD400'],
        xaxis: {
          type: 'datetime',
          categories: mappedSeries.length > 60
            ? mappedSeries.slice(mappedSeries.length - 60).map((data) => moment(data.timestamp * 1000).format('DD MMMM YYYY HH:mm'))
            : mappedSeries.map((data) => moment(data.timestamp * 1000).format('DD MMMM YYYY HH:mm')),
        },
        tooltip: {
          x: {
            enabled: false,
            format: 'dd/MM/yy HH:mm',
          },
        },
      };

      mappedSummary.tempratureChartSeries = [{
        name: 'Temprature',
        data: mappedSeries.length > 60
          ? mappedSeries.slice(-60).map((data) => (data?.fmData?.BUNKER_IN?.temperature || 0).toFixed(2))
          : mappedSeries.map((data) => (data?.fmData?.BUNKER_IN?.temperature || 0).toFixed(2)),
      }];
      mappedSummary.tempratureChart = {
        chart: {
          height: 80,
          type: 'area',
          sparkline: {
            enabled: true,
          },
          group: 'sparklines',
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 3,
          curve: 'smooth',
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.5,
            opacityTo: 0,
          },
        },
        series: mappedSummary.tempratureChartSeries,
        colors: ['#B141E6'],
        xaxis: {
          type: 'datetime',
          categories: mappedSeries.length > 60
            ? mappedSeries.slice(mappedSeries.length - 60).map((data) => moment(data.timestamp * 1000).format('DD MMMM YYYY HH:mm'))
            : mappedSeries.map((data) => moment(data.timestamp * 1000).format('DD MMMM YYYY HH:mm')),
        },
        tooltip: {
          x: {
            enabled: false,
            format: 'dd/MM/yy HH:mm',
          },
        },
      };

      mappedSummary.chartSeries = [
        {
          name: 'Mass Total',
          data: mappedSeries.map((data) => (data?.fmData?.BUNKER_IN?.massTotal || 0).toFixed(2)),
        },
        {
          name: 'Mass Flowrate',
          data: mappedSeries.map((data) => (data?.fmData?.BUNKER_IN?.massFlowrate || 0).toFixed(2)),
        },
        {
          name: 'Temprature',
          data: mappedSeries.map((data) => (data?.fmData?.BUNKER_IN?.temperature || 0).toFixed(2)),
        },
        {
          name: 'Density',
          data: mappedSeries.map((data) => (data?.fmData?.BUNKER_IN?.density || 0).toFixed(2)),
        },
      ];

      mappedSummary.chartOption = {
        chart: {
          height: 350,
          type: 'line',
          stacked: false,
        },
        colors: ['#0084ff', '#00E096', '#F94144', '#FFD400'],
        series: mappedSummary.chartSeries,
        stroke: {
          width: [3, 3, 3, 3],
          curve: 'smooth',
        },
        legend: {
          position: 'bottom',
        },
        xaxis: {
          type: 'datetime',
          labels: {
            formatter(val) {
              return new Date(val).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
            },
          },
          categories: mappedSeries.map((data) => moment(data.timestamp * 1000).format('DD MMMM YYYY HH:mm')),
        },
        yaxis: [
          {
            title: {
              text: 'Mass Total',
            },
          },
          {
            title: {
              text: 'Mass Flowrate',
            },
          },
          {
            opposite: true,
            title: {
              text: 'Density',
            },
          },
          {
            opposite: true,
            title: {
              text: 'Temprature',
            },
          },
        ],
      };

      mappedSummary.datalogs = mappedSeries.map((data, i) => ({
        id: i,
        datetime: data?.timestamp ? moment(data?.timestamp * 1000).format('DD MMMM YYYY HH:mm') : '-',
        mass_total: data?.fmData?.BUNKER_IN?.massTotal.toFixed(2) || '-',
        mass_flowrate: data?.fmData?.BUNKER_IN?.massFlowrate.toFixed(2) || '-',
        total_flow: data?.fmData?.BUNKER_IN?.volumeFlowrate.toFixed(2) || '-',
        density: data?.fmData?.BUNKER_IN?.density.toFixed(2) || '-',
        temperature: data?.fmData?.BUNKER_IN?.temperature.toFixed(2) || '-',
      }));

      return mappedSummary;
    },
    dateRange: {
      get() {
        const { start, end } = this.$store.getters['filter/date'];
        const startDate = new Date(moment(start).valueOf());
        const endDate = new Date(moment(end).valueOf());

        return { start: startDate, end: endDate };
      },
      set(val) {
        const start = moment(val.start);
        const end = moment(val.end);

        this.$store.commit('filter/SET_DATE', { start, end });
      },
    },
    selectedInterval: {
      get() {
        return this.interval;
      },
      set(val) {
        this.$store.dispatch('filter/setInterval', val);
      },
    },
    selectedAsset: {
      get() {
        return this.currentMassId;
      },
      set(val) {
        this.$store.dispatch('filter/setCurrentMassId', val);
      },
    },
  },
  methods: {
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    ...mapActions(['updatekapalTrail']),
    zoomToShip(ship) {
      this.center = new LatLng(ship.position[0], ship.position[1]);
      this.zoom = 15;
    },
    async onApplyFilter() {
      this.isLoading = true;
      try {
        // await this.$store.dispatch('filter/setDate', this.dateRange);
        await this.$store.dispatch('filter/setCurrentMassId', this.selectedAsset || this.currentMassId);
        await this.$store.dispatch('filter/setInterval', this.selectedInterval || this.interval);

        await this.$store.dispatch('asset/fetchSummary', {
          start: this.dateRange.start,
          end: this.dateRange.end,
          interval: this.interval,
          massId: this.currentMassId,
        });
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
.chart-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 5px;
  margin-top: 10px;
}

@media (max-width: 767.98px) {
  .chart-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
    margin-top: 10px;
  }
}

.counter {
  font-size: 1em;
}

.counter-unit {
  font-size: 0.7em;
  font-weight: 500;
  margin-left: 3px;
}

.chart-title {
  font-size: 1.2em;
  font-weight: 500;
  line-height: 3em;
}
</style>
