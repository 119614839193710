<template>
  <div style="height: calc(100vh - 70px); width: 100%">
    <Map v-if="!isLoading" :ships="assets" :center="center" :zoom="zoom" />
    <ShipSec v-if="!isLoading" :ships="assets" @update-trail="()=>{}" @zoom-to-ship="zoomToShip" />
  </div>
</template>

<script>
import Map from '@/views/home/components/Map';
import ShipSec from '@/views/home/components/ShipSec';
import { LatLng } from 'leaflet';
import { mapState } from 'vuex';

export default {
  name: 'Home',
  components: {
    Map,
    ShipSec,
  },
  data() {
    return {
      center: new LatLng(0, 0),
      zoom: 10,
      isLoading: true,
    };
  },
  computed: {
    ...mapState('asset', {
      assets: (state) => state.assets.filter((asset) => asset.lastGpsData),
    }),
  },
  methods: {
    zoomToShip(ship) {
      this.center = new LatLng(ship.position[0], ship.position[1]);
      this.zoom = 15;
    },
  },
  async mounted() {
    await this.$store.dispatch('asset/getAssetsData');
    await this.$store.dispatch('asset/getLastData');
    this.$store.dispatch('asset/getAllLocationData');
    this.$store.dispatch('asset/fetchAllLastSummary');
    this.isLoading = false;
  },
};
</script>
