import axios from 'axios';

export default {
  getAssets(config) {
    return axios.get('/master/asset', config);
  },
  getAsset(config) {
    return axios.get('/master/asset/', config);
  },
  getAssetByName(param) {
    return axios.get(`/master/asset?findField=${param.findField}&findValue=${param.findValue}`);
  },
  getAssetByType(param) {
    return axios.get(`/master/asset?findField=${param.findField}&findValue=${param.findValue}`);
  },
  addAsset(data) {
    return axios.post('/master/asset', data);
  },
  updateAsset(id, data) {
    return axios.put(`/master/asset/${id}`, data);
  },
  addOrUpdate(id = undefined, data) {
    if (id) {
      return this.updateAsset(id, data);
    }
    return this.addAsset(data);
  },
  deleteAsset(id) {
    return axios.delete(`/master/asset/${id}`);
  },
};
