import { render, staticRenderFns } from "./BunkerDashboard.vue?vue&type=template&id=2b1b21b8"
import script from "./BunkerDashboard.vue?vue&type=script&lang=js"
export * from "./BunkerDashboard.vue?vue&type=script&lang=js"
import style0 from "./BunkerDashboard.vue?vue&type=style&index=0&id=2b1b21b8&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports