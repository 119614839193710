<template>
  <div class="wrapper">
    <div class="d-flex align-items-center">
      <!-- date filter -->
      <div class="ml-3">
        <span class="d-block text-muted small text-left font-weight-bold mb-2">Select Range</span>
        <v-date-picker v-model="dateRange" is-range mode="datetime" :masks="masks" is24hr>
          <template #default="{ inputValue, inputEvents, isDragging }">
            <div class="d-flex flex-column flex-sm-row justify-content-start align-items-center">
              <div class="position-relative flex-grow-1">
                <input class="form-control bg-light border rounded" style="width: 320px;"
                  :class="isDragging ? 'text-muted' : 'text-dark'" :value="inputValue.start + ' - ' + inputValue.end"
                  v-on="inputEvents.start" />
              </div>
            </div>
          </template>
        </v-date-picker>
      </div>

      <div class="ml-3">
        <span class="d-block text-muted small text-left font-weight-bold mb-2">Interval</span>
        <select name="" class="form-control" v-model="selectedInterval">
          <option value="hour">Hour</option>
          <option value="day">Day</option>
          <option value="week">Week</option>
        </select>
      </div>

      <div class="ml-3">
        <span class="d-block text-muted small text-left font-weight-bold mb-2">Asset</span>
        <select name="" class="form-control" v-model="selectedAsset">
          <option v-for="asset in assets" :key="asset.massId" :value="asset.massId">{{ asset.massName }}</option>
        </select>
      </div>

      <div class="ml-3">
        <span class="d-block text-muted small text-left font-weight-bold mb-2">&nbsp;</span>
        <button class="btn btn-primary" @click="onApplyFilter">Apply</button>
      </div>
    </div>

    <template v-if="isLoading">
      <div class="text-center mt-5">
        <b-spinner label="Spinning"></b-spinner>
      </div>
    </template>

    <template v-else-if="currAssetSummary && !isLoading">
      <div class="flex-container mt-4">
        <div class="flex-item iq-card" style="width: 70%;">
          <div class="iq-card-header pt-2">
            <div class="iq-card-title">
              <h5 class="card-title">Main Engine RPM Summary</h5>
            </div>
          </div>

          <div class="iq-card-body">
            <div class="flex-container">
              <table class="table">
                <thead class="thead-light">
                  <th>RPM Summary</th>
                  <th>Value</th>
                  <th>Unit</th>
                </thead>
                <tbody>
                  <tr>
                    <td><b>Average Port RPM</b></td>
                    <td>{{ currAssetSummary?.rpm?.port?.average }}</td>
                    <td>RPM</td>
                  </tr>
                  <tr>
                    <td><b>Port Engine Running Hour</b></td>
                    <td>{{ currAssetSummary?.rpm?.port?.runningTime }}</td>
                    <td>Hour</td>
                  </tr>
                  <!-- <tr>
                    <td><b>Max Port Rpm</b></td>
                    <td>1350</td>
                    <td>0 Liter</td>
                  </tr>
                  <tr>
                    <td><b>Average Port RPM per NM</b></td>
                    <td>1350</td>
                    <td>RPM</td>
                  </tr> -->
                </tbody>
              </table>
              <table class="table ml-3">
                <thead class="thead-light">
                  <th>RPM Summary</th>
                  <th>Value</th>
                  <th>Unit</th>
                </thead>
                <tbody>
                  <tr>
                    <td><b>Average Starboard RPM</b></td>
                    <td>{{ currAssetSummary?.rpm?.starboard?.average }}</td>
                    <td>RPM</td>
                  </tr>
                  <tr>
                    <td><b>Starboard Engine Running Hour</b></td>
                    <td>{{ currAssetSummary?.rpm?.port?.runningTime }}</td>
                    <td>Hour</td>
                  </tr>
                  <!-- <tr>
                    <td><b>Max Starboard Rpm</b></td>
                    <td>1350</td>
                    <td>0 Liter</td>
                  </tr>
                  <tr>
                    <td><b>Average Starboard RPM per NM</b></td>
                    <td>1350</td>
                    <td>RPM</td>
                  </tr> -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="flex-item iq-card" style="width: 30%;">
          <div class="iq-card-header pt-2">
            <div class="iq-card-title">
              <h5 class="card-title">Auxilliary Engine Summary</h5>
            </div>
          </div>
          <div class="iq-card-body">
            <table class="table">
              <thead class="thead-light">
                <th>Data Summary</th>
                <th>Hour</th>
                <th>Fuel Cons.</th>
              </thead>
              <tbody>
                <tr>
                  <td><b>AE 1</b></td>
                  <td>{{ currAssetSummary?.ae?.ae1?.runningSeconds }}</td>
                  <td>{{ currAssetSummary?.ae?.ae1?.fuelConsumption }} Liter</td>
                </tr>
                <tr>
                  <td><b>AE 2</b></td>
                  <td>{{ currAssetSummary?.ae?.ae2?.runningSeconds }}</td>
                  <td>{{ currAssetSummary?.ae?.ae2?.fuelConsumption }} Liter</td>
                </tr>
                <tr>
                  <td><b>AE 3</b></td>
                  <td>{{ currAssetSummary?.ae?.ae3?.runningSeconds }}</td>
                  <td>{{ currAssetSummary?.ae?.ae3?.fuelConsumption }} Liter</td>
                </tr>
                <tr>
                  <td><b>Total</b></td>
                  <td><b>{{ currAssetSummary?.ae?.runningSeconds }}</b></td>
                  <td><b>{{ currAssetSummary?.ae?.fuelConsumption }} Liter</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="flex-container mt-2">
        <div class="flex-item iq-card" style="width: 70%;">
          <div class="iq-card-header pt-2">
            <div class="iq-card-title">
              <h5 class="card-title">Main Engine Fuel Summary</h5>
            </div>
          </div>

          <div class="iq-card-body">
            <div class="flex-container">
              <table class="table">
                <thead class="thead-light">
                  <th>Fuel Summary</th>
                  <th>Value</th>
                  <th>Unit</th>
                </thead>
                <tbody>
                  <tr>
                    <td><b>Port Fuel Cons</b></td>
                    <td>{{ currAssetSummary?.fm?.port?.engineCons }}</td>
                    <td>Liter</td>
                  </tr>
                  <tr>
                    <td><b>Port Flow Time</b></td>
                    <td>{{ currAssetSummary?.fm?.port?.runningTime }}</td>
                    <td>Hour</td>
                  </tr>
                  <tr>
                    <td><b>Port Fuel Flow</b></td>
                    <td>{{ currAssetSummary?.fm?.port?.avgFuelFlow }}</td>
                    <td>L/h</td>
                  </tr>
                </tbody>
              </table>
              <table class="table ml-3">
                <thead class="thead-light">
                  <th>Fuel Summary</th>
                  <th>Value</th>
                  <th>Unit</th>
                </thead>
                <tbody>
                  <tr>
                    <td><b>Starboard Fuel Cons</b></td>
                    <td>{{ currAssetSummary?.fm?.starboard?.engineCons }}</td>
                    <td>Liter</td>
                  </tr>
                  <tr>
                    <td><b>Starboard Flow Time</b></td>
                    <td>{{ currAssetSummary?.fm?.starboard?.runningTime }}</td>
                    <td>Hour</td>
                  </tr>
                  <tr>
                    <td><b>Starboard Fuel Flow</b></td>
                    <td>{{ currAssetSummary?.fm?.starboard?.avgFuelFlow }}</td>
                    <td>L/h</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="flex-item iq-card" style="width: 30%;">
          <div class="iq-card-header pt-2">
            <div class="iq-card-title">
              <h5 class="card-title">Fuel Consumption Summary</h5>
            </div>
          </div>
          <div class="iq-card-body">
            <table class="table">
              <thead class="thead-light">
                <th>Fuel Summary</th>
                <th>Value</th>
                <th>Unit</th>
              </thead>
              <tbody>
                <tr>
                  <td><b>ME Fuel Cons</b></td>
                  <td>{{ currAssetSummary?.fm?.meEngineCons }}</td>
                  <td>Liter</td>
                </tr>
                <tr>
                  <td><b>AE Fuel Cons</b></td>
                  <td>{{ currAssetSummary?.ae?.fuelConsumption }}</td>
                  <td>Liter</td>
                </tr>
                <tr>
                  <td><b>Total Fuel Cons</b></td>
                  <td><b>{{ parseFloat(currAssetSummary?.fm?.meEngineCons) +
                    parseFloat(currAssetSummary?.ae?.fuelConsumption) }}</b></td>
                  <td><b>Liter</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- <div class="flex-container mt-2">
      <div class="flex-item iq-card" style="width: 100%;">
        <div class="iq-card-header pt-2">
          <div class="iq-card-title">
            <h5 class="card-title">Fuel Engine Usage per RPM</h5>
          </div>
        </div>
        <div class="iq-card-body">
          <div class="flex-container">
            <div class="flex-item" style="width: 50%">
              <table class="table">
                <thead class="thead-light">
                  <th>Fuel Summary</th>
                  <th>Running Time</th>
                  <th>Fuel Per Rpm</th>
                  <th>Unit</th>
                </thead>
                <tfoot class="thead-light">
                  <th style="color:black">Total</th>
                  <th style="color:black">24:00:00</th>
                  <th style="color:black">160 Liter</th>
                  <th style="color:black">160 L/H</th>
                </tfoot>
                <tbody>
                  <tr v-for="number in numbers" :key="number">
                    <td><b>{{ number }} - {{ number + 99 }}</b></td>
                    <td>02:02:00</td>
                    <td>{{ ((number / 600) * 5 * 2).toFixed(2) }} Liter</td>
                    <td>{{ ((number / 600) * 5).toFixed(2) }} L/H</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="flex-item" style="width: 50%;">
              <ApexChart element="FuelConsPerRpm" :chartOption="FuelConsPerRpmChart" :series="FuelConsPerRpmSeries" />
            </div>
          </div>
        </div>
      </div>
    </div> -->

      <div class="flex-container mt-2">
        <div class="map-section" style="width: 100%; min-height: 454px;">
          <Map v-if="currAsset" :ships="[currAsset]" :center="center" :zoom="zoom"
            style="border-radius: 20px; margin-top: 10px; max-height: 100%;" />
        </div>
      </div>

      <div class="flex-container mt-4">
        <div class="flex-item iq-card" style="width: 100%;">
          <div class="iq-card-header pt-2">
            <div class="iq-card-title">
              <h5 class="card-title">RPM, Fuel Cons, And Speed Chart</h5>
            </div>
          </div>
          <div class="iq-card-body">
            <div class="row">
              <div class="col text-center">
                <div>
                  <h5 class="">{{ currAssetSummary?.rpm?.port?.average }}</h5>
                  <span class="">Avg. Port RPM</span>
                </div>
              </div>
              <div class="col text-center">
                <div>
                  <h5 class="">{{ currAssetSummary?.rpm?.starboard?.average }}</h5>
                  <span class="">Avg. Starboard RPM</span>
                </div>
              </div>
              <div class="col text-center">
                <div>
                  <h5 class="">{{ currAssetSummary?.gps?.averageSpeed }} Knot</h5>
                  <span class="">Avg. Speed</span>
                </div>
              </div>
            </div>
            <ApexChart element="RpmFuelSpeed" :chartOption="currAssetSummary.RpmFuelSpeedChart"
              :series="currAssetSummary.RpmFuelSpeedSeries" />
          </div>
        </div>
      </div>

      <div class="flex-container mt-2">
        <div class="flex-item iq-card" style="width: 100%;">
          <div class="iq-card-header pt-2">
            <div class="iq-card-title">
              <h5 class="card-title">Port Fuel Flow And Density</h5>
            </div>
          </div>
          <div class="iq-card-body">
            <div class="row">
              <div class="col text-center">
                <div>
                  <h5 class="">{{ currAssetSummary?.rpm?.port?.average }}</h5>
                  <span class="">Avg. Port RPM</span>
                </div>
              </div>
              <div class="col text-center">
                <div>
                  <h5 class="">{{ currAssetSummary?.fm?.port?.avgFuelFlow }}</h5>
                  <span class="">Avg. Port Flowrate</span>
                </div>
              </div>
              <div class="col text-center">
                <div>
                  <h5 class="">{{ currAssetSummary?.fm?.starboard?.avgDensity }}</h5>
                  <span class="">Avg. Port Density</span>
                </div>
              </div>
            </div>
            <ApexChart element="PortFuelDensity" :chartOption="currAssetSummary?.portFuelDensity"
              :series="currAssetSummary?.portFuelDensitySeries" />
          </div>
        </div>
      </div>

      <div class="flex-container mt-2">
        <div class="flex-item iq-card" style="width: 100%;">
          <div class="iq-card-header pt-2">
            <div class="iq-card-title">
              <h5 class="card-title">Starboard Fuel Flow & Density</h5>
            </div>
          </div>
          <div class="iq-card-body">
            <div class="row">
              <div class="col text-center">
                <div>
                  <h5 class="">{{ currAssetSummary?.rpm?.starboard?.average }}</h5>
                  <span class="">Avg. Starboard RPM</span>
                </div>
              </div>
              <div class="col text-center">
                <div>
                  <h5 class="">{{ currAssetSummary?.fm?.starboard?.avgFuelFlow }}</h5>
                  <span class="">Avg. Starboard Flowrate</span>
                </div>
              </div>
              <div class="col text-center">
                <div>
                  <h5 class="">{{ currAssetSummary?.fm?.starboard?.avgDensity }}</h5>
                  <span class="">Avg. Starboard Density</span>
                </div>
              </div>
            </div>
            <ApexChart element="StarboardFuelDensity" :chartOption="currAssetSummary?.starboardFuelDensity"
              :series="currAssetSummary?.starboardFuelDensitySeries" />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import Map from '@/views/home/components/Map';
import { LatLng } from 'leaflet';
import { toHHMMSS, generateTimestampsInSeconds } from '@/util/date';

const FuelConsPerRpmSeries = [{
  data: [{
    x: '600',
    y: 13.3,
  }, {
    x: '700',
    y: 24.2,
  }, {
    x: '800',
    y: 33,
  }, {
    x: '900',
    y: 45,
  }, {
    x: '1000',
    y: 55,
  }, {
    x: '1100',
    y: 60,
  }, {
    x: '1200',
    y: 88,
  }],
}];
const FuelConsPerRpmChart = {
  chart: {
    type: 'bar',
  },
  plotOptions: {
    bar: {
      horizontal: false,
      borderRadius: 10,
    },
  },
  series: FuelConsPerRpmSeries,
};

const RpmFuelSpeedChartSeries = [
  {
    name: 'Speed',
    data: [3.50, 3.5, 3.7, 3.8, 4.0, 4.3, 4.5, 4.7, 4.9, 5.2, 5.5],
  },
  {
    name: 'Port RPM',
    data: [431, 453, 511, 575, 622, 677, 756, 840, 860, 880, 934],
  },
  {
    name: 'Starboard RPM',
    data: [453, 450, 521, 568, 613, 680, 780, 876, 870, 890, 921],
  },
];
const RpmFuelSpeedChartCategories = Array.from({ length: 12 }, (_, i) => new Date(Date.UTC(2024, 7, 15, i)).getTime());
const RpmFuelSpeedChart = {
  chart: {
    height: 350,
    width: '100%',
    type: 'line',
    stacked: false,
  },
  colors: ['#0084ff', '#F94144', '#FF9A3D'],
  series: RpmFuelSpeedChartSeries,
  stroke: {
    width: [3, 3, 3, 3, 3],
  },
  legend: {
    position: 'top',
  },
  xaxis: {
    type: 'datetime',
    labels: {
      formatter(val) {
        return new Date(val).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
      },
    },
    categories: RpmFuelSpeedChartCategories,
  },
  yaxis: [
    {
      title: {
        text: 'Speed (km/h)',
      },
    },
    {
      opposite: true,
      title: {
        text: 'RPM',
      },
    },
  ],
};

const PortFuelDensityChartSeries = [
  {
    name: 'Port RPM',
    data: [431, 453, 511, 575, 622, 677, 756, 840, 860, 880, 934],
  },
  {
    name: 'Port Density',
    data: [0, 120, 150, 120, 150, 120, 150, 120, 150, 120, 150],
  },
];
const PortFuelDensityChartCategories = Array.from({ length: 12 }, (_, i) => new Date(Date.UTC(2024, 7, 15, i)).getTime());
const PortFuelDensityChart = {
  chart: {
    height: 350,
    width: '100%',
    type: 'line',
    stacked: false,
  },
  colors: ['#0084ff', '#F94144'],
  series: PortFuelDensityChartSeries,
  stroke: {
    width: [3, 3, 3, 3, 3],
  },
  legend: {
    position: 'top',
  },
  xaxis: {
    type: 'datetime',
    labels: {
      formatter(val) {
        return new Date(val).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
      },
    },
    categories: PortFuelDensityChartCategories,
  },
  yaxis: [
    {
      title: {
        text: 'Speed (km/h)',
      },
    },
    {
      opposite: true,
      title: {
        text: 'RPM',
      },
    },
  ],
};

const StarboardFuelDensityChartSeries = [
  {
    name: 'Starboard RPM',
    data: [453, 450, 521, 568, 613, 680, 780, 876, 870, 890, 921],
  },
  {
    name: 'Starboard Density',
    data: [0, 123, 121, 180, 123, 168, 158, 169, 132, 175, 154],
  },
];
const StarboardFuelDensityChartCategories = Array.from({ length: 12 }, (_, i) => new Date(Date.UTC(2024, 7, 15, i)).getTime());
const StarboardFuelDensityChart = {
  chart: {
    height: 350,
    width: '100%',
    type: 'line',
    stacked: false,
  },
  colors: ['#0084ff', '#F94144'],
  series: StarboardFuelDensityChartSeries,
  stroke: {
    width: [3, 3, 3, 3, 3],
  },
  legend: {
    position: 'top',
  },
  xaxis: {
    type: 'datetime',
    labels: {
      formatter(val) {
        return new Date(val).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
      },
    },
    categories: StarboardFuelDensityChartCategories,
  },
  yaxis: [
    {
      title: {
        text: 'Speed (km/h)',
      },
    },
    {
      opposite: true,
      title: {
        text: 'RPM',
      },
    },
  ],
};

export default {
  name: 'FmsReport',
  components: {
    Map,
  },
  data() {
    return {
      center: new LatLng(0, 0),
      zoom: 10,
      numbers: [],
      FuelConsPerRpmSeries,
      FuelConsPerRpmChart,
      RpmFuelSpeedChartSeries,
      RpmFuelSpeedChart,
      PortFuelDensityChartSeries,
      PortFuelDensityChart,
      StarboardFuelDensityChartSeries,
      StarboardFuelDensityChart,
      masks: {
        input: 'DD-MM-YYYY HH:mm',
      },
      isLoading: true,
    };
  },
  created() {
    for (let i = 600; i <= 1300; i += 100) {
      this.numbers.push(i);
    }
  },
  async mounted() {
    const interval = this.$store.getters['filter/interval'];
    if (interval === 'minute') {
      this.$store.commit('filter/SET_INTERVAL', 'hour');
    }
    const date = this.$store.getters['filter/date'];

    let currentMassId = this.$store.getters['filter/currentMassId'];
    let assets = this.$store.getters['asset/assets'];

    if (assets.length === 0) {
      await this.$store.dispatch('asset/getAssetsData');
      await this.$store.dispatch('asset/getLastData');
      assets = this.$store.getters['asset/assets'];
    }

    if (currentMassId === null) {
      await this.$store.dispatch('filter/setCurrentMassId', assets[0].massId);
      currentMassId = this.$store.getters['filter/currentMassId'];
    }

    await this.$store.dispatch('asset/fetchSummary', {
      start: date.start,
      end: date.end,
      interval,
      massId: currentMassId,
    });
    this.isLoading = false;
  },
  computed: {
    currAsset() {
      const currMassId = this.$store.getters['filter/currentMassId'];
      const assets = this.$store.getters['asset/assets'];
      if (!assets) return null;

      return assets.find((asset) => asset.massId === currMassId);
    },
    currAssetSummary() {
      const summary = this.currAsset?.summary || null;
      const mappedSummary = {};

      if (!summary) return null;

      const interval = this.$store.getters['filter/interval'];
      // eslint-disable-next-line no-nested-ternary
      const intervalSeconds = interval === 'hour' ? 3600 : interval === 'minute' ? 60 : 86400;

      const unixRangeStart = moment(this.dateRange.start).unix();
      const unixRangeEnd = moment(this.dateRange.end).unix();

      const intervalCount = Math.ceil((unixRangeEnd - unixRangeStart) / intervalSeconds);

      // GPS Mapping
      const lastGpsData = Object.values(summary.gps?.data || [{}])[Object.values(summary.gps?.data || [{}]).length - 1];
      const gpsCount = Object.values(summary.gps?.data || []).length;

      mappedSummary.gps = {
        lastGpsData,
        averageSpeed: (summary.gps?.summary?.averageSpeed * 0.5399568034557236).toFixed(2),
        totalDistance: (summary.gps?.summary?.totalDistance / 1852).toFixed(2),
        lastData: lastGpsData ? moment(lastGpsData.timestamp * 1000).format('DD MMMM YYYY HH:mm') : '-',
        percentage: ((gpsCount / intervalCount) * 100).toFixed(1),
      };
      // end of GPS Mapping

      // RPM Mapping
      const lastPortRpmData = Object.values(summary.rpm?.data || []).length > 0
        ? Object.values(summary.rpm?.data)[Object.values(summary.rpm?.data).length - 1].PORT
        : {};
      const lastStarboardRpmData = Object.values(summary.rpm?.data || []).length > 0
        ? Object.values(summary.rpm?.data)[Object.values(summary.rpm?.data).length - 1].STARBOARD
        : {};

      const rpmCount = Object.values(summary.rpm?.data || []).length;

      mappedSummary.rpm = {
        port: {
          lastData: lastPortRpmData ? moment(lastPortRpmData.timestamp * 1000).format('DD MMMM YYYY HH:mm') : '-',
          runningTime: toHHMMSS(summary.rpm?.summary?.runningTime?.PORT || 0),
          average: (summary.rpm?.summary?.average?.PORT || 0).toFixed(2),
          lastRpmData: lastPortRpmData,
        },
        starboard: {
          lastData: lastStarboardRpmData ? moment(lastStarboardRpmData.timestamp * 1000).format('DD MMMM YYYY HH:mm') : '-',
          runningTime: toHHMMSS(summary.rpm?.summary?.runningTime?.STARBOARD || 0),
          average: (summary.rpm?.summary?.average?.STARBOARD || 0).toFixed(2),
          lastRpmData: lastStarboardRpmData,
        },
        percentage: ((rpmCount / intervalCount) * 100).toFixed(1),
      };
      // end of RPM Mapping

      // FM Mapping
      const lastPortFmData = Object.values(summary?.flowmeter?.data || []).length > 0
        ? Object.values(summary.flowmeter.data)[Object.values(summary.flowmeter.data).length - 1]?.PORT
        : {};
      const lastStarboardFmData = Object.values(summary?.flowmeter?.data || []).length > 0
        ? Object.values(summary.flowmeter.data)[Object.values(summary.flowmeter.data).length - 1]?.STARBOARD
        : {};

      const fmCount = Object.values(summary?.flowmeter?.data || []).length;

      mappedSummary.fm = {
        port: {
          avgFuelFlow: (summary.flowmeter?.summary?.portAverageFlow || 0).toFixed(2),
          engineCons: (summary.flowmeter?.summary?.portEngineCons || 0).toFixed(2),
          lastData: lastPortFmData ? moment(lastPortFmData.timestamp * 1000).format('DD MMMM YYYY HH:mm') : '-',
          runningTime: toHHMMSS(summary.flowmeter?.summary?.PORT_IN?._flowTime || 0),
          avgDensity: (summary.flowmeter?.summary?.PORT_IN?._averageDens || 0).toFixed(2),
          lastFmData: lastPortFmData,
        },
        starboard: {
          avgFuelFlow: (summary.flowmeter?.summary?.starboardAverageFlow || 0).toFixed(2),
          engineCons: (summary.flowmeter?.summary?.starboardEngineCons || 0).toFixed(2),
          lastData: lastStarboardFmData ? moment(lastStarboardFmData?.timestamp * 1000).format('DD MMMM YYYY HH:mm') : '-',
          runningTime: toHHMMSS(summary.flowmeter?.summary?.STARBOARD_IN?._flowTime || 0),
          avgDensity: (summary.flowmeter?.summary?.STARBOARD_IN?._averageDens || 0).toFixed(2),
          lastFmData: lastStarboardFmData,
        },
        meEngineCons: (summary.flowmeter?.summary?.meEngineCons || 0).toFixed(2),
        percentage: ((fmCount / intervalCount) * 100).toFixed(1),
      };
      // end of FM Mapping

      // AE Mapping
      const lastAeData = Object.values(summary?.ae?.data || []).length > 0
        ? Object.values(summary?.ae?.data)[Object.values(summary.ae?.data).length - 1]
        : null;
      const lastAeKey = Object.keys(summary?.ae?.data || []).length > 0
        ? Object.keys(summary?.ae?.data)[Object.keys(summary.ae?.data || [{}]).length - 1]
        : null;

      const aeCount = Object.keys(summary?.ae?.data || []).length;

      mappedSummary.ae = {
        ae1: {
          fuelConsumption: (summary.ae?.summary?.fuelConsumption?.AE1 || 0).toFixed(2),
          runningSeconds: toHHMMSS(summary.ae?.summary?.runningSeconds?.AE1 || 0),
        },
        ae2: {
          fuelConsumption: (summary.ae?.summary?.fuelConsumption?.AE2 || 0).toFixed(2),
          runningSeconds: toHHMMSS(summary.ae?.summary?.runningSeconds?.AE2 || 0),
        },
        ae3: {
          fuelConsumption: (summary.ae?.summary?.fuelConsumption?.AE3 || 0).toFixed(2),
          runningSeconds: toHHMMSS(summary.ae?.summary?.runningSeconds?.AE3 || 0),
        },
        lastAeData,
        lastData: lastAeKey ? moment(lastAeKey * 1000).format('DD MMMM YYYY HH:mm') : '-',
        percentage: ((aeCount / intervalCount) * 100).toFixed(1),
        runningSeconds: toHHMMSS((summary.ae?.summary?.runningSeconds?.AE1 || 0) + (summary.ae?.summary?.runningSeconds?.AE2 || 0) + (summary.ae?.summary?.runningSeconds?.AE3 || 0)),
        fuelConsumption: ((summary.ae?.summary?.fuelConsumption?.AE1 || 0) + (summary.ae?.summary?.fuelConsumption?.AE2 || 0) + (summary.ae?.summary?.fuelConsumption?.AE3 || 0)).toFixed(2),
      };
      // end of AE Mapping

      const timestamps = generateTimestampsInSeconds(moment(this.dateRange.start).unix(), moment(this.dateRange.end).unix(), this.interval === 'minute' ? 60 : 3600);

      const roundedGps = {};
      Object.keys(summary.gps?.data || {}).forEach((key) => {
        const roundedKey = Math.round(key / 60) * 60;
        roundedGps[roundedKey] = summary.gps?.data[key];
      });

      const roundedRpm = {};
      Object.keys(summary.rpm?.data || {}).forEach((key) => {
        const roundedKey = Math.round(key / 60) * 60;
        roundedRpm[roundedKey] = summary.rpm?.data[key];
      });

      const roundedFm = {};
      Object.keys(summary.flowmeter?.data || {}).forEach((key) => {
        const roundedKey = Math.round(key / 60) * 60;
        roundedFm[roundedKey] = summary.flowmeter?.data[key];
      });

      const roundedAe = {};
      Object.keys(summary.ae?.data || {}).forEach((key) => {
        const roundedKey = Math.round(key / 60) * 60;
        roundedAe[roundedKey] = summary.ae?.data[key];
      });

      const mappedSeries = timestamps.map((timestamp) => {
        const gpsData = roundedGps[timestamp] || {};
        const rpmData = roundedRpm[timestamp] || {
          STARBOARD: {},
          PORT: {},
        };
        const fmData = roundedFm[timestamp] || {};
        const aeData = roundedAe[timestamp] || {};
        return {
          timestamp,
          gpsData,
          rpmData,
          fmData,
          aeData,
        };
      });

      mappedSummary.series = mappedSeries;

      mappedSummary.RpmFuelSpeedSeries = [
        {
          name: 'Speed',
          data: mappedSeries.map((data) => (data.gpsData.speed || 0).toFixed(2)),
        },
        {
          name: 'Port Flow',
          data: mappedSeries.map((data) => (data.fmData.PORT_InFlow || 0).toFixed(2)),
        },
        {
          name: 'Starboard Flow',
          data: mappedSeries.map((data) => (data.fmData.STARBOARD_InFlow || 0).toFixed(2)),
        },
        {
          name: 'Port RPM',
          data: mappedSeries.map((data) => (data.rpmData.PORT.rpm || 0).toFixed(2)),
        },
        {
          name: 'Starboard RPM',
          data: mappedSeries.map((data) => Math.floor(data.rpmData.STARBOARD.rpm || 0).toFixed(2)),
        },
      ];
      mappedSummary.RpmFuelSpeedChart = {
        series: mappedSummary.RpmFuelSpeedSeries,
        chart: {
          height: 350,
          width: '100%',
          type: 'line',
          stacked: false,
        },
        colors: ['#0084ff', '#884DFF', '#00E096', '#F94144', '#FF9A3D'],
        stroke: {
          width: [3, 3, 3, 3, 3],
          curve: 'smooth',
        },
        legend: {
          position: 'bottom',
        },
        xaxis: {
          type: 'datetime',
          labels: {
            formatter(val) {
              return moment(val).format('DD-MM-YYYY HH:mm');
            },
          },
          categories: mappedSeries.map((data) => moment(data.timestamp * 1000).format('DD MMMM YYYY HH:mm')),
        },
        yaxis: [
          {
            title: {
              text: 'Speed',
            },
          },
          {
            title: {
              text: 'Port Flow',
            },
          },
          {
            title: {
              text: 'Starboard Flow',
            },
          },
          {
            opposite: true,
            title: {
              text: 'Port RPM',
            },
          },
          {
            opposite: true,
            title: {
              text: 'Starboard RPM',
            },
          },
        ],
      };

      mappedSummary.portFuelDensitySeries = [
        {
          name: 'Port Flow',
          data: mappedSeries.map((data) => (data.fmData.PORT_InFlow || 0).toFixed(2)),
        },
        {
          name: 'Port RPM',
          data: mappedSeries.map((data) => (data.rpmData.PORT.rpm || 0).toFixed(2)),
        },
        {
          name: 'Port Density',
          data: mappedSeries.map((data) => (data.fmData.PORT_InDens || 0).toFixed(2)),
        },
      ];
      mappedSummary.portFuelDensity = {
        series: mappedSummary.portFuelDensitySeries,
        chart: {
          height: 350,
          width: '100%',
          type: 'line',
          stacked: false,
        },
        colors: ['#0084ff', '#884DFF', '#00E096', '#F94144', '#FF9A3D'],
        stroke: {
          width: [3, 3, 3, 3, 3],
          curve: 'smooth',
        },
        legend: {
          position: 'bottom',
        },
        xaxis: {
          type: 'datetime',
          labels: {
            formatter(val) {
              return moment(val).format('DD-MM-YYYY HH:mm');
            },
          },
          categories: mappedSeries.map((data) => moment(data.timestamp * 1000).format('DD MMMM YYYY HH:mm')),
        },
        yaxis: [
          {
            title: {
              text: 'Port Flow',
            },
          },
          {
            opposite: true,
            title: {
              text: 'Port RPM',
            },
          },
          {
            opposite: true,
            title: {
              text: 'Port Density',
            },
          },
        ],
      };

      mappedSummary.starboardFuelDensitySeries = [
        {
          name: 'Starboard Flow',
          data: mappedSeries.map((data) => (data.fmData.STARBOARD_InFlow || 0).toFixed(2)),
        },
        {
          name: 'STARBOARD RPM',
          data: mappedSeries.map((data) => (data.rpmData.STARBOARD.rpm || 0).toFixed(2)),
        },
        {
          name: 'Starboard Density',
          data: mappedSeries.map((data) => (data.fmData.STARBOARD_InDens || 0).toFixed(2)),
        },
      ];
      mappedSummary.starboardFuelDensity = {
        series: mappedSummary.starboardFuelDensitySeries,
        chart: {
          height: 350,
          width: '100%',
          type: 'line',
          stacked: false,
        },
        colors: ['#0084ff', '#884DFF', '#00E096', '#F94144', '#FF9A3D'],
        stroke: {
          width: [3, 3, 3, 3, 3],
          curve: 'smooth',
        },
        legend: {
          position: 'bottom',
        },
        xaxis: {
          type: 'datetime',
          labels: {
            formatter(val) {
              return moment(val).format('DD-MM-YYYY HH:mm');
            },
          },
          categories: mappedSeries.map((data) => moment(data.timestamp * 1000).format('DD MMMM YYYY HH:mm')),
        },
        yaxis: [
          {
            title: {
              text: 'Starboard Flow',
            },
          },
          {
            opposite: true,
            title: {
              text: 'Starboard RPM',
            },
          },
          {
            opposite: true,
            title: {
              text: 'Starboard Density',
            },
          },
        ],
      };

      return mappedSummary;
    },
    dateRange: {
      get() {
        const { start, end } = this.$store.getters['filter/date'];
        const startDate = new Date(moment(start).valueOf());
        const endDate = new Date(moment(end).valueOf());

        return { start: startDate, end: endDate };
      },
      set(val) {
        const start = moment(val.start);
        const end = moment(val.end);

        this.$store.commit('filter/SET_DATE', { start, end });
      },
    },
    ...mapState('asset', ['assets']),
    ...mapState('filter', {
      currentMassId: (state) => state.currentMassId,
      interval: (state) => state.interval,
    }),
    selectedInterval: {
      get() {
        return this.interval;
      },
      set(val) {
        this.$store.dispatch('filter/setInterval', val);
      },
    },
    selectedAsset: {
      get() {
        return this.currentMassId;
      },
      set(val) {
        this.$store.dispatch('filter/setCurrentMassId', val);
      },
    },
  },
  methods: {
    ...mapActions(['updatekapalTrail']),
    zoomToShip(ship) {
      this.center = new LatLng(ship.position[0], ship.position[1]);
      this.zoom = 15;
    },
    async onApplyFilter() {
      this.isLoading = true;
      try {
        // await this.$store.dispatch('filter/setDate', this.dateRange);
        await this.$store.dispatch('filter/setCurrentMassId', this.selectedAsset || this.currentMassId);
        await this.$store.dispatch('filter/setInterval', this.selectedInterval || this.interval);

        await this.$store.dispatch('asset/fetchSummary', {
          start: this.dateRange.start,
          end: this.dateRange.end,
          interval: this.interval,
          massId: this.currentMassId,
        });
      } catch (err) {
        console.err(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};

</script>

<style scoped>
.flex-container {
  display: flex;
  justify-content: space-between;
}

.flex-item {
  /* padding: 10px 20px; */
  margin: 0 5px;
}

@media screen and (max-width: 600px) {
  .flex-container {
    flex-direction: column;
  }

  .flex-item {
    width: 100%;
  }
}
</style>
